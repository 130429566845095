/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react";
 import PropTypes from "prop-types";
 import { Helmet } from "react-helmet";
 import site from '../../content/site.json';
 import { useLocation } from "@reach/router";

 function SEO({ description, image, title, article }) {
    const { pathname } = useLocation();
    
   const {
     defaultTitle,
     titleTemplate,
     defaultDescription,
     siteUrl,
     defaultImage,
   } = site.siteMetadata;
 
   const seo = {
     title: title || defaultTitle,
     description: description || defaultDescription,
     image: `${siteUrl}${image || defaultImage}`,
     url: `${siteUrl}${pathname}`,
   };
 
   return (
     <Helmet title={seo.title} titleTemplate={titleTemplate}>
       <meta name="description" content={seo.description} />
       <meta name="image" content={seo.image} />
       {seo.url && <meta property="og:url" content={seo.url} />}
       {(article ? true : null) && <meta property="og:type" content="article" />}
       {seo.title && <meta property="og:title" content={seo.title} />}
       {seo.description && (
         <meta property="og:description" content={seo.description} />
       )}
       {seo.image && <meta property="og:image" content={seo.image} />}
     </Helmet>
   );
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   title: PropTypes.string.isRequired,
   image: PropTypes.string,
   article: PropTypes.bool,
 };
 
 SEO.defaultProps = {
   description: null,
   title: null,
   image: null,
   article: false,
 };
 
 export default SEO;